import edgeDataApi from 'src/apis/edgeDataApi';
import _cloneDeep from 'lodash/cloneDeep';


/**
 * Sits between fetch/put individual layouts and templates.
 * Doesn't control other layout/template methods (like list, delete, etc).
 *
 * Will cache the response of an individual layout or template, up to a max of 4+1 per type.
 * Cache must be written to on put/save.
 */
class CacheLayer {
  TYPES = {
    LAYOUT: 'charts',
    TEMPLATES: 'study-templates'
  };

  constructor() {
    this.MAX_NUM_CACHED_PER_TYPE = 4;
    this.cachedPromises = {};
  }

  cacheKey(type, clientId, recordId) {
    return `${type}#${clientId}#${recordId}`;
  }

  fetch(type, componentId, clientId, recordId) {
    const key = this.cacheKey(type, clientId, recordId);
    const cachedProm = this.cachedPromises[key];

    const timestamp = +new Date();
    if (cachedProm) {
      this.cachedPromises[key].timestamp = timestamp;
      return cachedProm.prom;
    }

    const newProm = this.fetchNetwork(type, clientId, recordId);
    this.cachedPromises[key] = {
      prom: newProm,
      timestamp
    };

    this.checkExpiredPromises();

    return newProm;
  }


  async fetchNetwork(type, clientId, recordId) {
    const { data } = await edgeDataApi.get(`/user/${type}/${clientId}/${recordId}`);
    if (!data || !data.name || !data.id || !data.content) {
      throw Error('Invalid chart layout fetched');
    }
    data.content = JSON.parse(data.content);
    return data;
  }


  async patch(type, componentId, clientId, recordId, content) {
    try {
      await edgeDataApi.patch(`/user/${type}/${clientId}/${recordId}`, { content });
    } catch (err) {
      console.error(err);
    }

    const timestamp = +new Date();
    const key = this.cacheKey(type, clientId, recordId);
    if (key in this.cachedPromises) {
      this.cachedPromises[key].prom = Promise.resolve({
        id: recordId,
        content,
        lasModified: timestamp,
        name: 'cached_modified_name' // TODO: We don't have access to name here. Although it doesn't matter, name is fetched from list, its ugly.
      });
      this.cachedPromises.timestamp = timestamp;
    }
  }


  checkExpiredPromises() {
    // Seperate cached promises into two buckets
    const types = {};
    for (const [key, value] of Object.entries(this.cachedPromises)) {
      const [type] = key.split('#');
      types[type] = types[type] || [];
      types[type].push({ key, timestamp: value.timestamp });
    }

    // If a bucket has more than MAX promises, delete the extra ones, oldest first.
    //  But, don't add promises that are only 1 second old. That 1 second limit
    //  helps if we have tons of charts loading at the same time, like on page load
    //  with 6 different charts/layouts. We don't want to delete pending promises.
    //  The next time a chart loads, the promises will be discared properly.
    const date_now = +new Date();

    Object.keys(types).forEach(type => {
      types[type]
        .sort((b, a) => b.timestamp - a.timestamp)
        .slice(this.MAX_NUM_CACHED_PER_TYPE, types[type].length)
        .filter(({ timestamp }) => date_now - timestamp >= 1000)
        .forEach(({ key }) => {
          delete this.cachedPromises[key];
        });
    });
  }
}


class TradingViewLayoutManager {

  constructor() {
    this.cacheLayer = new CacheLayer();
    this.components = {};
    this.pendingLayoutAutosaves = new Map();
    this.pendingAutosaveDelaysActive = new Map();
    this.AUTOSAVE_DELAY = 5000;
  }


  putComponent(componentId, args) {
    this.components[componentId] = {
      ...this.components[componentId],
      ...args
    };
  }


  deleteComponent(componentId) {
    delete this.components[componentId];
  }


  registerWidget(componentId, widget) {
    this.putComponent(componentId, { widget });


    if (widget.current) {
      widget.current?.onChartReady(() => {
        widget.current.subscribe('drawing_event', async (id, reason) => {
          if (reason === 'click') {
            return;
          }
          if (this.components[componentId].isSyncing) {
            // Otherwise, we'll get infinite renders.
            return;
          }

          if (reason === 'move' || reason === 'points_changed') {
            // For some reason, the chart will export stale data even after the event is fired.
            // Wait a sec to make sure we have fresh data.
            await new Promise(r => setTimeout(r, 100));
          }

          widget.current.save(data => {
            this.syncBetweenComponents(componentId, data);
            this.saveLayout(componentId, data);
          });
        });

      });
    }
  }


  manualSave(componentId, widget) {
    if (widget.current) {
      widget.current?.onChartReady(() => {
        widget.current.save(data => {
          if (!(componentId in this.components)) return;
          const { clientId, layoutId } = this.components[componentId];

          this.syncBetweenComponents(componentId, data);

          this.cacheLayer.patch(
            this.cacheLayer.TYPES.LAYOUT,
            componentId,
            clientId,
            layoutId,
            data
          );
        });
      });
    }
  }


  registerComponent(componentId, clientId, layoutId, templateId, ticker, interval) {
    this.putComponent(componentId, { clientId, layoutId, templateId, ticker, interval });
  }


  unregesterComponent(componentId) {
    this.deleteComponent(componentId);
  }


  async fetchLayout(componentId, clientId, layoutId) {
    const data = _cloneDeep(await this.cacheLayer.fetch(
      this.cacheLayer.TYPES.LAYOUT,
      componentId,
      clientId,
      layoutId
    ));

    data.content = this.modifyLayoutState(data.content, componentId);
    return data;
  }

  // I'm passing in widget here because the this.registerWidget is called after this function.
  // TODO: Figure out better fix. Maybe ready handlers?
  // TODO: Maybe we want to combine layouts and templates here
  async fetchAndLoadLayout(componentId, { widget }, debug_name = '') {
    if (!(componentId in this.components)) return;

    // console.log(`[fetchAnLoadLayout] ${this.components[componentId]}, ${this.components}`);
    const { clientId, layoutId } = this.components[componentId];

    const data = _cloneDeep(await this.cacheLayer.fetch(
      this.cacheLayer.TYPES.LAYOUT,
      componentId,
      clientId,
      layoutId
    ));

    data.content = this.modifyLayoutState(data.content, componentId);

    if (widget.current) {
      widget.current?.onChartReady(() => {
        const templateData = widget.current.activeChart().createStudyTemplate({ saveSymbol: false, saveInterval: false });
        widget.current._options.datafeed.resetCache();
        widget.current.load(data.content);
        widget.current.activeChart().applyStudyTemplate(this.modifyTemplateState(templateData, componentId));
      });
    }
  }


  async fetchAndLoadTemplate(componentId, { widget }) {
    if (!(componentId in this.components)) return;
    const { clientId, templateId } = this.components[componentId];
    const data = _cloneDeep(await this.cacheLayer.fetch(
      this.cacheLayer.TYPES.TEMPLATES,
      componentId,
      clientId,
      templateId
    ));

    if (widget.current) {
      widget.current?.onChartReady(() => {
        widget.current._options.datafeed.resetCache();
        widget.current.activeChart().applyStudyTemplate(this.modifyTemplateState(data.content, componentId));
      });
    }
  }

  async fetchTemplate(componentId) {
    const { clientId, templateId } = this.components[componentId];
    const { data } = await edgeDataApi.get(`/user/study-templates/${clientId}/${templateId}`);
    if (!data || !data.name || !data.id || !data.content) {
      throw Error('Invalid chart layout fetched');
    }
    data.content = JSON.parse(data.content);
    return data;
  }


  modifyLayoutState(content, componentId, barSpacing, rightOffset) {
    if (!(componentId in this.components)) {
      return content;
    }
    const { ticker, resolution, interval } = this.components[componentId];

    content.charts[0].panes.forEach((pane, pIdx) => {
      pane.sources.forEach((source, sIdx) => {
        if (source.type === 'MainSeries') {
          content.charts[0].panes[pIdx].sources[sIdx].state.symbol = ticker;
          content.charts[0].panes[pIdx].sources[sIdx].state.shortName = ticker;
          content.charts[0].panes[pIdx].sources[sIdx].state.interval = interval;
          // content.charts[0].panes[pIdx].sources[sIdx].state.interval = resolution;
        }
      });
    });

    if (barSpacing) {
      content.charts[0].timeScale.m_barSpacing = barSpacing;
    }
    if (rightOffset) {
      content.charts[0].timeScale.m_rightOffset = rightOffset;
    }

    return content;
  }


  modifyTemplateState(content, componentId) {
    const { ticker, resolution, interval } = this.components[componentId];

    content.panes.forEach(pane => {
      pane.sources.forEach(source => {
        if (source.type === 'MainSeries') {
          source.state.symbol = ticker;
          source.state.shortName = ticker;
          // source.state.interval = resolution;
          source.state.interval = interval
        }
      });
    });

    return content;
  }


  saveTemplate(componentId, content) {
    if (!(componentId in this.components)) return;

    const { clientId, templateId } = this.components[componentId];
    this.cacheLayer.patch(
      this.cacheLayer.TYPES.TEMPLATES,
      componentId,
      clientId,
      templateId,
      content
    );

    for (const [cid, component] of Object.entries(this.components)) {
      if (component?.templateId && component.templateId === templateId && component?.widget?.current) {
        component.widget.current.onChartReady(() => {
          component.widget.current._options.datafeed.resetCache();
          component.widget.current.activeChart().applyStudyTemplate(this.modifyTemplateState(content, cid));
        });
      }
    }
  }


  saveLayout(componentId, content) {
    if (!(componentId in this.components)) return;

    const { clientId, layoutId } = this.components[componentId];

    const autosaveKey = `${clientId}#${layoutId}`;
    this.pendingLayoutAutosaves.set(autosaveKey, content);

    if (!this.pendingAutosaveDelaysActive.get(autosaveKey)) {
      this.pendingAutosaveDelaysActive.set(autosaveKey, true);
      setTimeout(() => {
        const data = this.pendingLayoutAutosaves.get(autosaveKey);
        // TODO: Do we want cache put on a timer? We want network put, but probably not cache put.
        this.cacheLayer.patch(
          this.cacheLayer.TYPES.LAYOUT,
          componentId,
          clientId,
          layoutId,
          data
        );
        this.pendingAutosaveDelaysActive.delete(autosaveKey);
        this.pendingLayoutAutosaves.delete(autosaveKey);
      }, this.AUTOSAVE_DELAY);
    }
  }


  syncBetweenComponents(sourceComponentId, content) {
    const sourceComponent = this.components[sourceComponentId];

    Object.keys(this.components).forEach(targetComponentId => {
      if (targetComponentId !== sourceComponentId) {
        const targetComponent = this.components[targetComponentId];
        if (sourceComponent?.layoutId && targetComponent?.layoutId === sourceComponent.layoutId) {
          if (targetComponent.widget.current) {
            targetComponent.widget.current.onChartReady(() => {
              targetComponent.isSyncing = true;
              const timeScaleApi = targetComponent.widget.current.activeChart().getTimeScale();
              const barSpacing = timeScaleApi.barSpacing();
              const rightOffset = timeScaleApi.rightOffset();
              targetComponent.widget.current._options.datafeed.resetCache();
              const templateData = targetComponent.widget.current.activeChart().createStudyTemplate({ saveSymbol: false, saveInterval: false });
              targetComponent.widget.current.load(
                this.modifyLayoutState(content, targetComponentId, barSpacing, rightOffset)
              );
              targetComponent.widget.current.activeChart().applyStudyTemplate(
                this.modifyTemplateState(templateData, targetComponentId)
              );
              targetComponent.isSyncing = false;
            });
          }
        }
      }
    });
  }
}


const tva = new TradingViewLayoutManager();
export default tva;
