import edgeUsersApi from 'src/apis/edgeUsersApi';
import { batch } from 'react-redux';
import { PREDEF_PREFIX, PROFS } from 'src/redux/profileSettings/profileSettingsConfig';
import wrapSingleArgWithArray from 'src/utils/wrapSingleArgWithArray';
import { predefinedHistoryRecordsProfile, predefinedHistoryTopBarProfiles } from 'src/redux/profileSettings/historyProfileSettingsConfig';
import { predefinedFullKeyStatsProfile, predefinedOverviewHistoryProfile } from 'src/redux/profileSettings/overviewProfileSettingsConfig';
import { isObjEmpty } from 'src/utils/isObjEmpty';
import { predefinedTopListFilterProfiles } from './toplistProfileSettingsConfig';
import { EXPR_VERSION, INITIALIZE_EXPRESSIONS, BEGIN_FETCH_EXPRESSIONS, MARK_EXPRESSIONS_FETCHED } from '../expressions/globalExpressionReducer';
import { userNamespacedLocalStorageMiddleware } from '../store/UserNamespacedLocalStorageMiddleware';


export const UPDATE_FULL_PROFILE = '@profile-settings/update-full-profile';
export const UPDATE_SINGLE_PROFILE = '@profile-settings/update-single-profile';
export const ADD_TOPIC_TOPLIST_NEWS = '@profile-settings/add-topic-toplist-news';
export const REMOVE_TOPIC_TOPLIST_NEWS = '@profile-settings/remove-topic-toplist-news';
export const REMOVE_NEWS_PAGE_TOPIC_FILTERS = '@profile-settings/remove-topic-news-news;';
export const ADD_NEWS_PAGE_TOPIC_FILTERS = '@profile-settings/add-topic-news-news;';
export const ADD_METRIC_GLOBAL_TITLE_BAR = '@profile-settings/add-topic-global-titlebar';
export const ADD_NEWS_PAGE_SEC_TOPICS = '@profile-settings/news-sec-add-topic';
export const REMOVE_NEWS_PAGE_SEC_TOPICS = '@profile-settings/news-sec-remove-topic';
export const REMOVE_METRIC_GLOBAL_TITLE_BAR = '@profile-settings/remove-topic-global-titlebar';
export const REORDER_METRIC_GLOBAL_TITLE_BAR = '@profile-settings/reorder-topic-global-titlebar';
export const UPDATE_FEED_TOPLIST_NEWS = '@profile-settings/update-feed-toplist-news';
export const UPDATE_FEED_NEWS_PAGE = '@profile-settings/update-feed-news-news';
export const UPDATE_FILTER_TOPLIST_NEWS = '@profile-settings/update-filter-toplist-news';
export const UPDATE_FILTER_NEWS_PAGE = '@profile-settings/update-filter-news-news';
export const SET_AUDIO_ENABLED_TOPLIST_NEWS = '@profile-settings/set-audio-enabled-toplist-news';



// Profiles are saved under sort key of page#type.
// You can fetch either a specific page#type profile, or all profiles for 'page'.
// When saving profiles, you can only save a specific page#type.


/**
 * Theres lots of data we want to only fetch once, but after Authentication.
 * Do all that here.
 *
 * Don't want it in /user because that refetches frequently.
 *
 * Expression stuff is a complete mess, as usual.
 *
 * TEST:
 *  Existing user:
 *  On dev, set resolution/templateId before deploy
 *  deploy
 *  check chart and expressions
 *
 *  New user:
 *  - Make sure expressions initialize
 *  - Then, make sure they update
 *  - Then, delete all expressions and make sure empty array is returned
 *    - from local
 *    - from db
 *
 **/
export const fetchGlobalUserSettings = () => async (dispatch, getState) => {
  const state = getState();
  const user = state?.account?.user || {};
  const userSub = user?.userSub;

  try {
    // MIGRATE LOCALSTORAGE FOR HISTORY PAGE CHART
    const res = localStorage.getItem(`tvchart_history_comp#resolution`);
    const tem = localStorage.getItem(`tvchart_history_comp#templateId`);

    if (res) {
      localStorage.setItem(`tvchart_history_comp#resolution#${userSub}`, res);
    }
    if (tem) {
      localStorage.setItem(`tvchart_history_comp#templateId#${userSub}`, tem);
    }

    localStorage.removeItem(`tvchart_history_comp#templateId`);
    localStorage.removeItem(`tvchart_history_comp#resolution`);
  } catch (err) {
    console.error(err);
  }

  try {
    const params = { profile: PROFS.page.GLOBAL };

    const ACTIONS = { INITIALIZE: 'i', FETCH: 'f', HYDRATE: 'h' };
    let action = ACTIONS.FETCH;

    let localExprPayload = [];
    let dbExprPayload = [];
    let dbTimestamp = 0;

    const slice = userNamespacedLocalStorageMiddleware.getSlice('expressions');


    if (!(slice.databaseKey in user) || !user?.[slice.databaseKey]) {
      action = ACTIONS.INITIALIZE; // User never interacted with expressions
    } else {
      dbTimestamp = user[slice.databaseKey];

      const [
        shouldHydrate,
        expressionLocalData
      ] = userNamespacedLocalStorageMiddleware.getHydrateLocalData('expressions', dbTimestamp);

      if (shouldHydrate) {
        action = ACTIONS.HYDRATE;
        localExprPayload = expressionLocalData?.expressions;
        // We should use localstorage data
        dispatch({
          type: INITIALIZE_EXPRESSIONS,
          payload: { expressions: localExprPayload }
        });
      } else {
        dispatch({ type: BEGIN_FETCH_EXPRESSIONS });
        params.expressions = EXPR_VERSION;
        action = ACTIONS.FETCH;
      }
    }

    const response = await edgeUsersApi.get(`/user/global`, { params });
    const { profile, expressions } = response?.data || {};
    dbExprPayload = expressions;

    batch(() => {
      if (profile) {
        dispatch({ type: UPDATE_FULL_PROFILE, payload: { data: profile, page: PROFS.page.GLOBAL } })
      }
      if (action === ACTIONS.FETCH) {
        console.log('EXP_HYD FROM SERVER', dbTimestamp, dbExprPayload);
        // We should use DB data, and save to localstorage
        dispatch({
          type: INITIALIZE_EXPRESSIONS,
          writeLocalStorage: dbTimestamp,
          payload: { expressions: dbExprPayload }
        })
      } else if (action === ACTIONS.INITIALIZE) {
        dispatch({
          type: MARK_EXPRESSIONS_FETCHED,
          // writeLocalStorage: dbTimestamp // I can't tell if this is needed or wanted. IT won't save to db...
        })
      }
    });

  } catch (err) {
    console.log('[accountActions - fetchGlobalUserSettings]: ', err);
  }
}


export const fetchPageProfileSettings = (page) => async dispatch => {
  if (page === PROFS.page.GLOBAL) {
    console.error('GLOBAL profile now has dedicatd endpoint for combining data');
    // saveProfileSettings still works.
    return;
  }

  let res = [];
  try {
    const response = await edgeUsersApi.get(`/user/profiles/${page}`);
    let prof = {};
    if (response.data && !isObjEmpty(response.data)) {

      prof = addNewDefaultPageAttributes(response.data, page);

      if (page === PROFS.page.OVERVIEW) {
        prof = addPredefinedProfileSettings(prof, page, PROFS.type.KEYSTATS, predefinedFullKeyStatsProfile);
        prof = addPredefinedProfileSettings(prof, page, PROFS.type.HISTORY, predefinedOverviewHistoryProfile);
      } else if (page === PROFS.page.HISTORY) {
        prof = addPredefinedProfileSettings(prof, page, PROFS.type.TOP_BAR, predefinedHistoryTopBarProfiles);
        prof = addPredefinedProfileSettings(prof, page, PROFS.type.RECORDS, predefinedHistoryRecordsProfile);

        if (prof[page]?.[PROFS.type.TOP_BAR]) {
          prof[page][PROFS.type.TOP_BAR].profiles = migrateHistoryPageShareGroupToShareType(prof[page][PROFS.type.TOP_BAR].profiles);
        }
        if (prof[page]?.[PROFS.type.RECORDS]) {
          prof[page][PROFS.type.RECORDS].profiles = migrateHistoryPageShareGroupToShareType(prof[page][PROFS.type.RECORDS].profiles);
        }

      } else if (page === PROFS.page.TOP_LIST) {
        prof = addPredefinedProfileSettings(prof, page, PROFS.type.FILTER_PROFILES, predefinedTopListFilterProfiles);
      }

      // prof = forceActiveProfileToExist(prof);
    }
    dispatch({ type: UPDATE_FULL_PROFILE, payload: { data: prof, page } });
  } catch (err) {
    console.log('[accountActions - getPageSettingsProfile]: ', err);
  }
  return res;
};


export const saveProfileSettings = (incomingProfile, page, type, sendToReducer = false, enforceProfileExistsForKeys = []) => async dispatch => {
  let profile = JSON.parse(JSON.stringify(incomingProfile));

  // Optionally make sure that an activeProfile points to an ID that exists in the user's profiles. If it does not exist, set it to the first existing profile.
  // Created for the history page, in which ExportForm can delete activeProfiles, and top of page can delete exportProfiles
  if (enforceProfileExistsForKeys && enforceProfileExistsForKeys.length) {
    enforceProfileExistsForKeys.forEach(profKey => {
      const profileId = profile[profKey];
      let profIdx = profile.profiles.findIndex(prof => prof.id === profileId);
      if (profIdx === -1) {
        profIdx = 0;
        profile[profKey] = profile.profiles[profIdx].id;
      }
    });
  }

  let profileToDynamo = JSON.parse(JSON.stringify(profile));

  if (profile.profiles) {
    // remove predefined profiles so they're not saved to dynamo.
    profileToDynamo.profiles = profile.profiles.filter(({ id }) => !id.includes(PREDEF_PREFIX));

    // enforce max
    // if (profile.profiles.length > MAX_PROFILES) {
    //  profile.profiles = profile.profiles.slice(0, MAX_PROFILES);
    // }
  }

  // Usually true, sometimes you may want to save to the DB without updating the store.
  if (sendToReducer) {
    dispatch({ type: UPDATE_SINGLE_PROFILE, payload: { profile, page, type } });
  }

  try {
    await edgeUsersApi.post(`user/profiles/${page}/${type}`, profileToDynamo);
  } catch (err) {
    console.log('[profileSettings: postProfileSettings]: ', err);
  }
};


// Add predefined objects to the profiles inside the store. Will be removed when the profile is saved.
const addPredefinedProfileSettings = (pageProfiles, page, type, predefinedProfile) => {
  if (pageProfiles[page]?.[type]?.profiles && Array.isArray(pageProfiles[page][type].profiles)) {
    const existingProf = pageProfiles[page][type];
    pageProfiles[page][type].profiles = [...predefinedProfile, ...existingProf.profiles];

    const config = pageProfiles[page][type];
    const activeProfileExists = config.profiles.some(prof => {
      return prof.id === config.activeProfile;
    });

    if (!activeProfileExists) {
      pageProfiles[page][type].activeProfile = config.profiles[0].id;
    }
  }

  return pageProfiles;
};


// If a key saved inside a database profile no longer exists on the frontend, problems will occor.
// You can write a backend script whenever these change to modify everyone's profile to remove the keys,
// Or you can modify it here.
const addNewDefaultPageAttributes = (pageProfiles, page) => {

  // History#topBar profile filters changed from object to array
  if (page === PROFS.page.HISTORY && pageProfiles[page]?.[PROFS.type.TOP_BAR]) {
    const config = pageProfiles[page][PROFS.type.TOP_BAR];
    const isOldFormat = config.profiles.some(prof => {
      return !Array.isArray(prof.activeFilters);
    });
    if (isOldFormat) {
      pageProfiles[page][PROFS.type.TOP_BAR] = null;
    }
  }
  return pageProfiles;
};


export const addGlobalTitleBarMetrics = (metrics = []) => {
  return { type: ADD_METRIC_GLOBAL_TITLE_BAR, payload: wrapSingleArgWithArray(metrics) };
};

export const removeGlobalTitleBarMetrics = (metrics = []) => {
  return { type: REMOVE_METRIC_GLOBAL_TITLE_BAR, payload: wrapSingleArgWithArray(metrics) };
};

export const reorderGlobalTitleBarMetrics = (metrics) => {
  return { type: REORDER_METRIC_GLOBAL_TITLE_BAR, payload: wrapSingleArgWithArray(metrics) };
};


/**
 * share_type is being changed to share_group. 
 * share_type still exists in the DB, but we no longer want to reference it.
 * 
 * CHANGES:
 *  history records (columns)
 *  history filters (filters, sort)
 *  history downloads (columns, filters, sort) - DONE IN exportActions.js!!! 
 **/


const SHARE_TYPE_TO_SHARE_GROUP_VALS = {
  'CS': 'CS',
  'WT': 'WT',
  'ETF': 'ET'
}

const SHARE_TYPE = 'share_type';
const SHARE_GROUP = 'share_group';


export const migrateHistoryPageShareGroupToShareType = (profileList) => {
  if (!profileList || !profileList.length) {
    return profileList;
  }

  return profileList.map(prof => {
    if (prof.activeFilters) {
      prof.activeFilters.forEach(filter => {
        if (filter.name === SHARE_TYPE) {
          filter.name = SHARE_GROUP;
          if (filter.selected) {
            filter.selected = SHARE_TYPE_TO_SHARE_GROUP_VALS[filter.selected] || 'CS';
          }
        }
      });
    }

    if (prof.activeColumns) {
      for (let i = 0; i < prof.activeColumns.length; i++) {
        if (prof.activeColumns[i] === SHARE_TYPE) {
          prof.activeColumns[i] = SHARE_GROUP;
        }
      }
    }

    if (prof.orderby === SHARE_TYPE) {
      prof.orderby = SHARE_GROUP;
    }

    return prof;
  });
}

